import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import SEO from "../components/SEO";
import SectionRow from "../components/SectionRow";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import Section from "../components/Section";
import SVGCurve from "../components/SVGCurve";
import AppointmentDiv from "../components/AppointmentDiv";

function NotFoundPage() {
  return (
    <LayoutGeneral>
      <SEO title="404: Not found" />
        <Section shade="mid">
            <SectionRow>
                <TextDiv>
                    <h2 className="text-3xl text-center mb-3">Page Not Found</h2>
                    <TextParagraph className="m-8">
                        Unfortunately the page you were looking for could not be found.
                    </TextParagraph>
                </TextDiv>
            </SectionRow>
        </Section>
        <SVGCurve direction="right-slope" transition="mid-dark"/>
        <Section shade="dark">
            <SectionRow>
                <AppointmentDiv/>
            </SectionRow>
        </Section>
    </LayoutGeneral>
  );
}

export default NotFoundPage;
